<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/03-1数字化产线.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title" >打造透明、高效、稳定的<br/>数字化生产管理平台</div>
            <div class="mobile-head-explain-content">生产稳定性大幅提升的秘诀</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">在国家“双碳”战略的引领下，产业结构加速升级，加快企业的数字化转型，可助力企业实现可持续发展。作
                为⼀家世界领先的果汁饮料公司，该企业在多个国家拥有多种饮料品牌，包括汽⽔、运动饮料、乳类饮品、果汁、茶和咖啡，业务范围⼴泛。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-box1">
                <div class="title1">
                    近年来，中国⻝品饮料零售市场快速增⻓，⾏业竞争加剧，该客户在⽣产效率上⾯临困境
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    在该客户供应链的管理链路中，企业需要耗费⼤量的⼈⼒物⼒进⾏数据采集及故障维修，来保障稳定⽣产
                </div>
            </div>
            <div class="ware-box1" style="margin-bottom:5rem">
                <div class="title1">
                    集团需要综合⼤量数据经验进⾏⽣产优化决策，达到降本增效的效果
                </div>
            </div>
            <div class="purchase-title" style="display:flex;"><div class="special-style">客户的主要需求：</div></div>
            <div class="purchase-content" style="display:flex; margin-top: 1rem;"><div class="purchase-doit">●</div><div class="special-style">提⾼⽣产的稳定性 - 减少现场⾮计划停机的时间，保证设备稳定;</div></div>
            <div class="purchase-content" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">降低⼯⼚的⽣产成本 - 降低⼈⼯成本，节省产线不必要的浪费，节约能源消耗;</div></div>
            <div class="purchase-content" style="display:flex;margin-bottom: 7rem;"><div class="purchase-doit">●</div><div class="special-style">提⾼供应链整体效率 - 通过现场反馈的问题以及数据，不断地优化整体的运⾏，缩短新品的上市周期。</div></div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="purchase-title" style="display:flex;"><div class="special-style">此解决⽅案是迎合供应链4.0时代与该⼯⼚实际需求相结合的项⽬，将⼯⼚实时⽣产情况和数据流相结合，对设备、资源、效率
                    进⾏有效监控，在优化资源配置、提⾼效率的同时，对可能出现的影响⽣产因素进⾏提前预警，提⾼⻛险防控的有效性：<br/><br/></div></div>
                <div class="purchase-content" style="display:flex;margin-top: -2rem;"><div class="purchase-doit">●</div><div class="special-style">⽹⻚和APP两种载体来进⾏跨平台的使⽤，能够在多终端设备使⽤；</div></div>
                <div class="purchase-content" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">提供集团驾驶舱，精益看板管理，展示⼯⼚产线上的实时指标数据，以及外围设备的关键指标，帮助⼯⼚管理⼈员能直观及时的查看⼯⼚数据并及时
                    干预；</div></div>
                <div class="purchase-content" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">系统同时设有总部和⼯⼚的切换，总部能够查看所有⼯⼚的关键总结数据，帮助决策层查看⼯⼚的⽣产效率和综合情况；</div></div>
                <div class="purchase-content" style="display:flex;margin-bottom: 5rem;"><div class="purchase-doit">●</div><div class="special-style">实现产线停机原因分析，建⽴多维度预警及故障分析体系框架，减少问题发⽣干预的滞后，提⾼⽣产稳定性及⽣产效率。</div></div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="purchase-title" style="display:flex;"><div class="special-style">经过⽐孚与⼯⼚的共同努⼒，客户的生产停机恢复时间有了明显改观和提升。实现产线停机原因分析，提⾼⽣产的稳定性；根据采集数据进⾏组合分析及可视化展示，实现设备、能耗、⼈员
                    透明化管理提供，降低管理成本；同时提供可视化看板及报表⼯具，建⽴故障反馈机制，减少⼈⼯抄表时间，实现⾼效⽣产。<br/><br/></div></div>
                <div class="purchase-title" style="display:flex;margin-top: -2rem;"><div class="special-style">这⼀解决⽅案使⽣产数字化平台的价值可以量化：</div></div>
                <div class="purchase-content" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">实现数据可视化、透明化管理，数据准确性⾼达 <span style="color: #E61611;font-size: 3rem;">100%</span>； </div></div>
                <div class="purchase-content" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">通过ME分析，订单完成率计算，以及⽣产运⾏的展示，⼈员效率提⾼ <span style="color: #E61611;font-size: 3rem;">50%</span>，ME提⾼  <span style="color: #E61611;font-size: 3rem;">8%</span>； </div></div>
                <div class="purchase-content" style="display:flex;margin-bottom: 5rem;"><div class="purchase-doit">●</div><div class="special-style">展示故障停机分析，计划外停机时间减少   <span style="color: #E61611;font-size: 3rem;">10%</span>，实现稳定⽣产。</div></div>
                
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 20rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    
    .card{
        width: 52.8rem;
        height: 18rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 2rem 0 0 2.1rem;
        }
        .title2{
            width: 43.2rem;
            height: 7.2rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 1.5rem 0 0 2.1rem;
        }
    }
}
.purchase-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
    .title-6{
        text-align: center;
        margin: auto;
        // width: 5rem;
        height: 5rem;
        font-size: 3rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #E61611;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-content{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;

    margin: 1rem 0 0 3.5rem;
}
.purchase-doit{
    color: #E61611 ;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
    margin-top: -0.7rem;
}
</style>